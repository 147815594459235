import { type Component, shallowRef } from 'vue';

export type ComponentProps<T> = T extends new () => { $props: infer P }
  ? NonNullable<P>
  : T extends (props: infer P, ...args: any) => any
    ? P
    : // eslint-disable-next-line @typescript-eslint/no-empty-object-type
      {};

export type UseDynamicModalOptions<T extends Component> = {
  component: T;
  attrs?: ComponentProps<T>;
};

export const dynamicModals = shallowRef<UseDynamicModalOptions<Component>[]>(
  [],
);

export const useDynamicModal = <T extends Component>(
  options: UseDynamicModalOptions<T>,
) => {
  const open = () => {
    if (dynamicModals.value.includes(options)) {
      throw new Error('The dynamic component was already opened');
    }
    dynamicModals.value = [...dynamicModals.value, options];
  };

  const close = () => {
    dynamicModals.value = dynamicModals.value.filter(
      (dynamicModal) => dynamicModal !== options,
    );
  };

  return { open, close };
};
