import { apiClient } from '@/api/index';
import { components, operations } from '~/api-types';
import { FileExportFormat } from '@/types/files';
import { getFileExportResponseType } from '@/utils/file';

export enum AccountType {
  ADMIN = 'admin',
  STANDARD = 'standard',
}

export enum AccountAvailability {
  DISABLED = 'disabled',
  ENABLED = 'enabled',
}

export enum AccountStatus {
  REGISTERED = 'registered',
  INVITED = 'invited',
  NOT_INVITED = 'not-invited',
}

export type PaginatedAccountDto =
  components['schemas']['PaginatedAccountResponseDto'];

export type GetAccountsListQueryParams =
  operations['AdminGetAccountsListEndpoint_handler']['parameters']['query'];

export type AccountDto = components['schemas']['AccountResponseDto'];
export type AccountWithOrganizationDto =
  components['schemas']['AccountWithOrganizationResponseDto'];
export type CreateAccountBodyDto =
  components['schemas']['CreateAccountBodyDto'];
export type UpdateAccountBodyDto =
  components['schemas']['UpdateAccountBodyDto'];
export type AccountSummaryMetricsResponseDto =
  components['schemas']['AccountSummaryMetricsResponseDto'];
export type AccountSummaryMetricsQueryParams =
  operations['AdminAccountsSummaryMetricsEndpoint_handler']['parameters']['query'];
export type ExportAccountsQueryParams =
  operations['AdminExportAccountsEndpoint_handler']['parameters']['query'];

export const apiGetAccountsList = async (
  params?: GetAccountsListQueryParams,
): Promise<PaginatedAccountDto> => {
  const { data } = await apiClient.get('/admin/accounts', {
    params,
  });

  return data;
};

export const apiGetCurrentAccount = async (
  organizationSlug?: string,
): Promise<AccountWithOrganizationDto> => {
  const { data } = await apiClient.get('/admin/accounts/current', {
    headers: { 'organization-slug': organizationSlug },
  });

  return data;
};

export const apiGetSingleAccount = async (
  accountId: string,
): Promise<AccountDto> => {
  const { data } = await apiClient.get(`/admin/accounts/${accountId}`);

  return data;
};

export const apiDeleteAccount = async (accountId: number): Promise<void> => {
  await apiClient.delete(`/admin/accounts/${accountId}`);
};

export const apiCreateAccount = async (
  payload: CreateAccountBodyDto,
): Promise<AccountDto> => {
  const { data } = await apiClient.post(`/admin/accounts`, payload);

  return data;
};

export const apiUpdateAccount = async (
  accountId: string,
  payload: UpdateAccountBodyDto,
): Promise<AccountDto> => {
  const { data } = await apiClient.patch(
    `/admin/accounts/${accountId}`,
    payload,
  );

  return data;
};

export type CreateAccountsBodyDto =
  components['schemas']['CreateAccountsBodyDto'];
export type CreateManyAccountsResponseDto =
  components['schemas']['CreateManyAccountsResponseDto'];
export type AccountDtoManyAccounts = components['schemas']['AccountDto'];

export const apiCreateManyAccounts = async (
  payload: CreateAccountsBodyDto,
): Promise<CreateManyAccountsResponseDto> => {
  const { data } = await apiClient.post(`/admin/accounts/create-many`, payload);

  return data;
};

export type CreateAccountsDependentsBodyDto =
  components['schemas']['CreateAccountsDependentsBodyDto'];
export type CreateManyDependentsResponseDto =
  components['schemas']['CreateManyDependentsResponseDto'];
export type DependentDtoManyDependents = components['schemas']['DependentDto'];

export const apiCreateManyDependents = async (
  payload: CreateAccountsDependentsBodyDto,
): Promise<CreateManyDependentsResponseDto> => {
  const { data } = await apiClient.post(
    `/admin/accounts/dependents/create-many`,
    payload,
  );

  return data;
};

export type DeleteAccountsBodyDto =
  components['schemas']['DeleteAccountsBodyDto'];

export const apiDeleteManyAccounts = async (
  payload: DeleteAccountsBodyDto,
): Promise<void> => {
  const { data } = await apiClient.post(`/admin/accounts/delete`, payload);

  return data;
};

export type GetAccountMetricsListQueryParams =
  operations['AdminGetAccountMetricsListEndpoint_handler']['parameters']['query'];
export type PaginatedAccountMetricsDto =
  components['schemas']['PaginatedAccountMetricsResponseDto'];

export const apiGetAccountMetricsList = async (
  accountId: string | number,
  params?: GetAccountMetricsListQueryParams,
): Promise<PaginatedAccountMetricsDto> => {
  const { data } = await apiClient.get(`/admin/accounts/${accountId}/metrics`, {
    params,
  });

  return data;
};

export const apiGetAccountMetricsCsv = async (
  accountId: string | number,
  exportFormat: FileExportFormat,
) => {
  const { data } = await apiClient.get(
    `/admin/accounts/${accountId}/metrics/${exportFormat}`,
    {
      responseType: getFileExportResponseType(exportFormat),
    },
  );

  return data;
};

export const apiGetSummaryAccountsMetrics = async (
  params?: AccountSummaryMetricsQueryParams,
): Promise<AccountSummaryMetricsResponseDto> => {
  const { data } = await apiClient.get(`/admin/accounts/summary-metrics`, {
    params,
  });

  return data;
};

export const apiExportAccounts = async (
  exportFormat: FileExportFormat,
  params: ExportAccountsQueryParams,
): Promise<void> => {
  const { data } = await apiClient.get(
    `/admin/accounts/export/${exportFormat}`,
    {
      params,
      responseType: getFileExportResponseType(exportFormat),
    },
  );

  return data;
};
