<template>
  <component
    :is="tag"
    :to="to"
    :type="parsedType"
    :href="href"
    :form="props.form"
    :class="btnClass"
  >
    <slot />
  </component>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { RouteLocationRaw } from 'vue-router';
import {
  BtnColor,
  BtnShape,
  BtnSize,
  BtnVariant,
} from '@/components/base/btn/btn.constants';

type Props = {
  to?: RouteLocationRaw;
  href?: string;
  type?: 'button' | 'submit' | 'reset';
  icon?: boolean;
  size?: BtnSize;
  variant?: BtnVariant;
  color?: BtnColor;
  shape?: BtnShape;
  form?: string;
};

const props = withDefaults(defineProps<Props>(), {
  size: BtnSize.MD,
  variant: BtnVariant.TEXT,
  shape: BtnShape.CIRCLE,
});

const btnClass = computed(() => {
  return [
    'btn',
    `size-${props.size}`,
    props.variant,
    props.color,
    `shape-${props.shape}`,
    { icon: props.icon },
  ];
});

const tag = computed<string>(() => {
  if (props.to !== undefined) {
    return 'router-link';
  }

  if (props.href !== undefined) {
    return 'a';
  }
  return 'button';
});

const parsedType = computed(() => {
  if (props.type) {
    return props.type;
  }
  if (props.to === undefined && props.href === undefined) {
    return 'button';
  }

  return null;
});
</script>

<style scoped>
.btn {
  @apply inline-flex items-center justify-center align-middle font-semibold px-6 transition duration-200 ease-in-out flex-shrink-0 cursor-pointer;

  &:hover:not([disabled]) {
    background-image: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1));
  }

  &:active:not([disabled]) {
    background-image: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1));
    transform: scale(0.95);
  }
}

.icon {
  @apply px-0;
}

.size- {
  &xs {
    @apply h-4 text-sm;
    &.icon {
      @apply w-4;
    }
  }
  &sm {
    @apply h-6 text-sm;
    &.icon {
      @apply w-6;
    }
  }
  &md {
    @apply h-8 text-sm;
    &.icon {
      @apply w-8;
    }
  }
  &lg {
    @apply h-10 text-sm;
    &.icon {
      @apply w-10;
    }
  }
}

.text {
  @apply border-none;
}
.outlined {
  @apply border border-current;
}

.primary {
  @apply text-primary;

  &.filled {
    @apply text-white bg-primary;
  }
}
.secondary {
  @apply text-secondary;

  &.filled {
    @apply text-white bg-secondary;
  }
}

.red {
  @apply text-secondary;

  &.filled {
    @apply text-secondary bg-pink-80;
  }
}

.green {
  @apply text-secondary;

  &.filled {
    @apply text-secondary bg-green-80;
  }
}

.yellow {
  @apply text-secondary;

  &.filled {
    @apply text-secondary bg-yellow-80;
  }
}

.orange {
  @apply text-secondary;

  &.filled {
    @apply text-secondary bg-red-80;
  }
}

.white {
  @apply text-secondary;

  &.filled {
    @apply bg-white;
  }
}

.gray {
  @apply border-gray-85 text-secondary;

  &.filled {
    @apply bg-gray-85;
  }

  &.outlined {
    @apply bg-white;
  }
}

.btn:disabled {
  @apply cursor-default text-secondary-80;

  &.filled {
    @apply bg-gray-92 text-white;
  }
}

.shape- {
  &rounded {
    @apply rounded;
  }

  &circle {
    @apply rounded-full;
  }
}
</style>
