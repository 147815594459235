import { ContentFileDto } from '@/api/contents';

export enum FileExportFormat {
  CSV = 'csv',
  XLSX = 'xlsx',
}

export type ContentPreviewFileDto = ContentFileDto & {
  isError: boolean;
  isLoading: boolean;
};
