<template>
  <template v-if="errorStore.isError">
    <error-page />
  </template>
  <template v-else>
    <notifications
      v-for="(notificationType, index) in Object.values(NotificationType)"
      :key="index"
      :width="isMobileView ? '90%' : '25rem'"
      :group="notificationType"
    >
      <template #body="{ item, close }">
        <notification
          :notification="item"
          :type="notificationType"
          @close="close"
        />
      </template>
    </notifications>
    <router-view #="{ Component, route }">
      <suspense timeout="0">
        <component :is="Component" />

        <template #fallback>
          <loading-modal />
        </template>
      </suspense>
    </router-view>
    <DynamicModalContainer />
    <div v-if="loaderStore.isLoading">
      <loading-modal />
    </div>
  </template>
</template>

<script setup lang="ts">
import { onErrorCaptured } from 'vue';
import { useRouter } from 'vue-router';
import { useErrorStore } from '@/store/use-error-store';
import { useLoaderStore } from '@/store/use-loader-store';
import { LoadingModal } from '@/components/base/modals/loading-modal';
import { ErrorPage } from '@/views/_components/error-page';
import { Notification, NotificationType } from '@/components/base/notification';
import { useWindowWidth } from '@/composables/use-window-width';
import { DynamicModalContainer } from '@/components/base/modals/dynamic-modal-container';

const router = useRouter();

const errorStore = useErrorStore();

onErrorCaptured<unknown>((err) => {
  errorStore.handleError(router, err);

  return true;
});

const loaderStore = useLoaderStore();
const { isMobileView } = useWindowWidth();
</script>
