export enum BtnSize {
  XS = 'xs',
  SM = 'sm',
  MD = 'md',
  LG = 'lg',
}

export enum BtnVariant {
  FILLED = 'filled',
  OUTLINED = 'outlined',
  TEXT = 'text',
}

export enum BtnColor {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  RED = 'red',
  GREEN = 'green',
  ORANGE = 'orange',
  YELLOW = 'yellow',
  WHITE = 'white',
  GRAY = 'gray',
}

export enum BtnShape {
  ROUNDED = 'rounded',
  CIRCLE = 'circle',
}
